@import "src/global-styles/colors";
@import "src/mixins/_mixins";

.Toastify {
  &__toast-container {
    //width: 348px;
    padding: 0;
    z-index: 1008;

    &--top-right {
      top: 91px;
      right: 15px;
    }
  }

  &__toast-body {
    display: flex;
    align-items: center;
  }

  &__toast {
    padding: 15px 52px 20px 15px;
    box-sizing: border-box;
    margin: 0;

    i {
      font-size: 1px;
      line-height: 1px;
      height: auto;
      width: auto;
      margin-right: 10px;
      align-self: flex-start;

      &.icon.info.circle:before {
        background: url("../../../assets/images/warning-icon.svg") no-repeat !important;
        content: "";
        width: 34px;
        height: 34px;
        display: inline-block;
      }

      &.icon.check.circle:before {
        background: url("../../../assets/images/success.svg") no-repeat !important;
        content: "";
        width: 24px;
        height: 24px;
        display: inline-block;
      }
    }
  }

  &__close-button {
    color: $notify-error-highlight;
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 14px;
    font-weight: bold;
  }

  h4 {
    @include textStyle($sans-bold, 14px, $gray--darkest, normal, 17px);
    margin: 0;
  }

  .title {
    display: inline-block;
    vertical-align: top;
  }

  .message {
    @include textStyle($sans-reg, 14px, $gray--darkest, normal, 17px);
  }

  h4+.message {
    margin: 8px 0 0;
  }

  &__toast--error {
    background-color: $notify-error;
    border-left: 4px solid $notify-error-highlight;
  }

  &__toast--info {
    background-color: $notify-info;
    border-left: 4px solid $notify-info-highlight;

    i,
    .Toastify__close-button {
      color: $notify-info-highlight;
    }
  }

  &__toast--success {
    background-color: $notify-success !important;
    border: none;
    border-radius: 7px !important;
    box-shadow: 0px 0px 20px 0 $notify-shadow !important;

    i,
    .Toastify__close-button {
      color: $black;
    }
  }

  &__toast--warning {
    border-left: 4px solid $notify-warn-highlight;
    border: none;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 14px 1px $notify-shadow;

    i,
    .Toastify__close-button {
      color: $notify-warn-highlight;
      color: #000;
    }
  }
}