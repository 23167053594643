@import "src/global-styles/typography";
@import "src/global-styles/colors";

.ack {


    &__modal {
        font-family: $sans-reg;
        width: 60% !important;
    }

    &__heading {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: #F48067;
        }
    }

    &__body {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #101010;
        margin: 20px 0;

        .sub-heading {
            margin: 10px 0;
        }

        .bullet-points {
            margin-left: 20px;

            ul {
                list-style-type: disc;

                li {
                    list-style-type: disc;

                    a {
                        color: #197A56;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    a:hover {
                        color: #197A56;
                        text-decoration: underline;
                    }
                }
            }

        }
    }

    &__footer {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;

        .action-container {
            margin-top: 10px;
            text-align: center;

            button.primary {
                background: #197A56;
                height: 40px;
                text-transform: uppercase;
                width: auto;
                border: 1px solid #197a56;
                color: #fff;
            }

            button.primary:hover {
                background-color: #2f4f4f;
            }

        }
    }

}