@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.layout__chat-section {
    padding: 0px;
}

.container__keywordsearch {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0 10px;


    .chatRoot {
        flex: 1;
        display: flex;
    }

    .chatContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-left: 50px;
    }

    .chatContainer.remove-padding {
        padding-left: 0px;
    }

    .chatEmptyState {
        width: 80%;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-height: 1024px;
        padding-top: 20px;

        .headingText {
            width: 460px;
            height: 300px;
        }

        .headingImage {
            position: absolute;
            top: 10px;
            right: 0px;
        }

        .chatEmptyMainImage.ui.image {
            height: 416px;
        }
    }

    .chatEmptyStateTitle {
        margin: 0;
        font-weight: 700;
        font-size: 47px;
        line-height: 53px;
        text-align: left;
        background: linear-gradient(179.86deg, #0A477D 0%, #197A56 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        position: relative;
        margin-bottom: 20px;
        font-family: $serif-bold;
        // text-fill-color: transparent;

    }


    .numOfTranscriptsContainer {

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: nowrap;

        img.numOfTranscriptsImage {
            display: inline-block;
            height: 22px;
        }

        .numOfTranscriptsText {
            font-size: 23.25px;
            font-weight: 700;
            color: #323232;
            background: linear-gradient(179.86deg, #0A477D 0%, #197A56 99.89%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            position: relative;
            width: 400px;
            font-family: $sans-reg;
            line-height: 25px;
        }
    }


    .chatEmptyStateSubtitle {
        margin: 0;
        font-weight: 700;
        font-size: 47px;
        line-height: 53px;
        text-align: center;

        background: linear-gradient(92.09deg, #3823EE 16.28%, #E464A6 55.47%, #ED9F60 101.65%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
    }

    .chatEmptyStateTitle::selection,
    .numOfTranscriptsText::selection,
    .chatEmptyStateSubtitle::selection {
        -webkit-text-fill-color: black;
    }


    .chatInput {
        // background: #F2F2F2;
        background: linear-gradient(90deg, #3823EE -0.95%, rgba(228, 100, 166, 0.504708) 49.05%, #ED9F60 100%);

        border-radius: 6px;
        padding: 2px 2px 1px;

        &__inner {
            width: 970px;
            // margin-left: 146px;
            margin-bottom: 10px;
            padding: 10px 20px;
            background: #F2F2F2;
            border-radius: 4px;
            position: relative;

            .tabsHeading {
                position: absolute;
                display: flex;
                top: -34px;

                .tab {
                    background: #f2f2f2;
                    height: 35px;
                    margin-right: 10px;
                    padding: 5px 20px;
                    color: $black;
                    font-size: 16px;
                    cursor: pointer;
                }

                .tab.active {
                    border-top: 2px solid #197A56;
                }

                .chat-tooltip {
                    position: absolute;
                    cursor: default;
                    top: -50px;
                    font-size: 14px;
                    color: #0056b3;
                    white-space: nowrap;
                    text-align: center;
                    border-radius: 4px;
                    height: 35px;
                    padding: 10px;
                }

                .chat-arrow {
                    width: 15px;
                    top: -15px;
                    height: 40px;
                    position: relative;
                    right: 15px;
                }

                .chat-arrow::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 15px solid blue;
                }
            }
        }

        &__disclamier {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: #535353;

            span {
                color: #D82216;
            }
        }

        &__heading {
            font-size: 18px;
            font-weight: 300;
            line-height: 27px;
            color: #323232;
            font-family: $sans-light;

            img.ui.image {
                display: inline-block;
            }
        }

        &__wrap {
            position: relative;
            bottom: 0;
            left: 0;
            background: #fff;
            display: flex;
            justify-content: center;
            flex-direction: row;

        }

        &__newChatButton {
            padding: 15px 0 0 15px;
        }

        &__newChat {
            font-family: Henderson BCG Sans;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: #197A56;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    .chatAnalysisPanel {
        flex: 1;
        overflow-y: auto;
        max-height: 89vh;
        margin-left: 20px;
        margin-right: 20px;
    }

    .chatSettingsSeparator {
        margin-top: 15px;
    }

    .loadingLogo {
        font-size: 28px;
    }

    .commandsContainer {
        display: flex;
        align-self: flex-end;
    }

    .commandButton {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}