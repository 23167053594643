@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.searchResults {
    height: auto;
    min-height: 465px;
    max-height: 750px;
    background: $gray--light;
    padding-left: 50px;

    &__filter-container {
        padding: 5px;
        display: flex;
        justify-content: space-between;

        .chatInput {
            background: linear-gradient(90deg, #3823EE -0.95%, rgba(228, 100, 166, 0.504708) 49.05%, #ED9F60 100%);
            border-radius: 6px;
            padding: 2px 2px 1px;
            width: 33%;
        }

        .filter-box {
            display: flex;
            height: 21px;
            margin: 11px 5px;
        }

        .numberOfSeacrhResults {
            span {
                font-family: $sans-reg;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                letter-spacing: 0.01em;
                text-align: left;
                color: $gray--700;
            }
        }

        .filter-parent {
            display: flex;
            font-size: 12px;
            margin-left: 10px;
            color: $black;

            .filter {
                background: $white;
                padding: 0px 10px;
                border-radius: 16px;
                margin-left: 5px;
                cursor: pointer;
                display: flex;

                img.rotate {
                    transform: rotate(180deg);
                }
            }

            .filter.disabled {
                cursor: not-allowed;
            }

            .date {
                img {
                    margin-right: 5px;
                    width: 12px;
                }
            }

            .sources,
            .more-filters,
            .company {
                img {
                    margin-left: 8px;
                    width: 12px;
                }
            }

            div.more-filter-container {
                position: relative;

                div.more-filter {
                    position: absolute;
                    width: auto;
                    left: -110px;
                    top: 24px;
                    background: #F1EEEA;
                    box-shadow: -3px -1px 7px rgba(0, 0, 0, 0.25);
                    max-height: 500px;
                    z-index: 2;
                    padding: 10px 10px 0;

                    div.filter-item {
                        padding-bottom: 10px;
                        width: auto;

                        span {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    &__transcripts {
        display: flex;
        padding-left: 5px;

        .list-view {
            overflow: auto;
            width: 50%;
            height: calc(100vh - 105px);
            margin-right: 8px;
            background: $gray--light;

            .loading-more-data {
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                margin: 5px 0;
            }

            .single-transcript {
                background-color: white;
                width: 100%;
                height: 135px;
                margin-bottom: 5px;
                display: flex;

                &__docviz-preview {
                    width: 23%;
                    padding: 10px;
                    background: $gray--300;
                    position: relative;


                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .expand-icon {
                        position: absolute;
                        z-index: 1;
                        opacity: 0.5;
                        bottom: 10px;
                        right: 10px;
                    }

                    .docviz-not-available {
                        padding: 16px;
                        background: white;
                        width: 100%;
                        height: calc(100% - 20px);
                    }

                    .docviz-preview-unavailable {
                        font-size: 10px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 11px;
                        letter-spacing: 0.01em;
                        padding: 4px 0px;
                    }
                }

                &__docviz-preview:hover {
                    .expand-icon {
                        opacity: 1;
                    }

                    img.docviz-image {
                        box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 40%);
                    }
                }

                .docviz-preview-skeleton {
                    background: white;
                }

                &__details {
                    width: 100%;
                    padding: 5px 10px;
                }

                &__generated-title {
                    font-family: $sans-reg;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $gray--700;
                    margin-bottom: 5px;
                    max-height: 35px;
                    overflow: hidden;

                    display: flex;

                    .generated-title {
                        width: 95%;
                    }

                    .bookmark-tooltip {
                        width: 5%;

                        .bookmark-icon {
                            float: right;
                        }
                    }
                }

                &__generated-title.skeleton {
                    display: block;
                }

                &__meta-data {
                    display: flex;
                    justify-content: space-between;

                    >div.meta-data {
                        display: flex;

                        span {
                            font-family: $sans-reg;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: $gray--700;
                            margin-left: 5px;
                        }

                        span.source-pill {
                            font-size: 10px;
                            background: #DFD7CD;
                            padding: 0 10px;
                            color: $black;
                            border-radius: 8px;
                        }

                        span.source-pill.bcg {
                            color: $white;
                            background-color: $bcggreenfive;
                        }
                    }
                }

                &__generated-summary {
                    .heading {
                        span {

                            font-family: $sans-reg;
                            font-size: 10px;
                            font-weight: 700;
                            line-height: 15px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #111111;

                        }
                    }

                    .summary {
                        max-height: 66px;
                        overflow: hidden;
                        margin-top: 3px;

                        p {
                            font-family: $sans-reg;
                            font-size: 12px;
                            font-weight: 400;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #000000;
                        }
                    }
                }
            }

            .single-transcript.isSelected {
                .single-transcript__generated-title {
                    color: $bcg-green;
                }
            }

            .single-transcript:hover {
                cursor: pointer;
            }
        }

        .list-view.no-transcript-available {
            width: 0;
            margin-right: 0;
        }

        .detalied-view {
            background-color: white;
            width: 100%;
            height: calc(100vh - 105px);
            overflow: auto;

            .no-transcript-selected {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                padding-top: 15%;

                .skelton-wrapper {
                    // width: 200px;
                    margin-top: 14%;
                }

                &__text {
                    text-align: center;
                    color: $gray--700;

                    p {
                        font-size: 24px;
                        margin: 0;
                    }

                    p:nth-child(2) {
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}