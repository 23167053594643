@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.transcript-detalied-view-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .meta-data-wrapper {
        display: flex;
        flex-direction: column;

        .top-section {
            display: flex;
            flex-direction: row;
            max-height: 60px;
            overflow: hidden;
            justify-content: space-between;

            .generated-title {
                width: 95%;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                color: $gray--700;

            }

            .action-icons {
                padding: 6px 12px 0 0;

                img {
                    cursor: pointer;
                }
            }

        }

        .mid-section {
            display: flex;
            justify-content: space-between;

            .left-section {
                width: 80%;

                .ui.secondary.pointing.menu {
                    border-bottom: 1px solid #D4D4D4
                }

                .ui.secondary.pointing.menu .active.item {
                    border-bottom: 2px solid #197A56
                }

                .ui.secondary.pointing.menu .item {
                    font-family: $sans-reg;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    padding-left: 2px;
                    padding-right: 2px;
                    margin-right: 20px;
                }

                .ui.segment {
                    border: none;
                    box-shadow: none;
                    padding: 0;
                }

                .generated-summary {
                    min-height: 58px;
                    max-height: 130px;
                    font-family: $sans-reg;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    overflow: auto;
                    color: #323232;
                }

                .meta-data-wrapper-1 {
                    display: flex;
                    flex-direction: row;
                    font-size: 12px;
                    color: #535353;
                    padding: 10px 0;
                    border-bottom: 1px solid #d4d4d4;

                    .meta-data {
                        margin-right: 10px;

                        b {
                            color: #323232;
                        }
                    }
                }

                .meta-data-wrapper-2 {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    color: #535353;
                    padding: 10px 0;

                    .meta-data {
                        margin-right: 10px;

                        span {
                            color: #323232;
                        }

                        span.source-pill {
                            font-size: 10px;
                            background: #DFD7CD;
                            padding: 2px 10px;
                            color: $black;
                            border-radius: 8px;
                            margin-left: 3px;
                            cursor: pointer;
                        }

                        span.source-pill.bcg {
                            color: $white;
                            background-color: $bcggreenfive;
                        }

                        .date {
                            color: #197A56;
                        }
                    }
                }

                .EET-wrapper {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 10px;

                    .company {
                        width: 40%;
                    }

                    .designation {
                        width: 40%
                    }

                    .date {
                        width: 15%;
                    }

                    .tenure {
                        width: 20%;
                    }


                }

                .expert-biography-topic {
                    border-top: 1px solid #d4d4d4;
                    border-bottom: 1px solid #d4d4d4;
                    margin: 10px 0;
                    padding: 10px 0;


                    .expert-biography-label {
                        font-size: 10px;
                        font-weight: 700;
                        color: #535353;
                    }

                    .expert-biography-text {
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 15px;
                        max-height: 150px;
                        overflow: auto;
                        color: #111111;
                        padding-right: 5px;
                    }
                }

                .table-item {
                    padding: 0 2px;
                }

                .table-row-heading {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #535353;
                    padding-bottom: 5px;
                }

                .table-body {
                    font-size: 10px;
                    font-weight: 700;
                    color: #323232;
                    max-height: 350px;
                    overflow: auto;

                    .no-expert-history {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        height: 100%;
                        padding-top: 35px;

                        &__text {
                            text-align: center;
                            color: $gray--400;

                            p {
                                font-size: 12px;
                                margin: 0;
                                font-weight: normal;
                            }
                        }
                    }

                    .table-row {
                        display: flex;
                        width: 100%;
                        padding: 5px 0;

                        .topics-header {
                            width: 28%;
                            font-weight: 400;
                            color: #535353;

                        }

                        .topics-value {
                            width: 72%;
                        }
                    }

                    .table-row:nth-child(odd) {
                        background: #F2F2F2;
                    }
                }


                .taxonomy-count {
                    width: 20px;
                    height: 19px;
                    background: #f2f2f2;
                    border: 1px solid #535353;
                    border-radius: 10px;
                    display: inline-block;
                    color: #000;
                    text-align: center;
                    font-size: 12px;
                    margin-left: 10px;
                }

                .tags-container {
                    display: flex;
                    flex-direction: column;

                    .tags-disclaimer {
                        background-color: #FFFBE8;
                        margin-bottom: 12px;
                        display: flex;

                        &__info-icon {
                            display: inline;
                            margin-left: 5px;
                        }

                        &__info-text {
                            display: inline;
                            color: #535353;
                            margin-left: 10px;
                            font-size: 12px;

                            line-height: 18px;
                        }
                    }

                    .tags {
                        width: 100%;
                        position: relative;

                        .tags-heading {
                            color: #535353;
                            font-size: 12px;
                            font-weight: 700;
                            margin-bottom: 10px;
                        }

                        .tag {
                            margin-bottom: 10px;

                            img.right-arrow {
                                margin: 0 5px;
                                display: inline;
                            }

                            span.parent-tag {
                                color: #333333;
                                font-size: 12px;
                            }

                            span.main-tag {
                                background: #D4D4D4;
                                padding: 0px 16px 0px 13px;
                                border-radius: 16px;
                                color: black;
                                font-size: 12px;
                                height: 21px;
                                display: inline-block;
                                font-weight: 400;

                            }
                        }
                    }

                    .action-container {
                        position: absolute;
                        right: 11px;
                        top: 0;

                        .action {
                            float: right;

                            span.expand_collapse_text {
                                color: #333;
                                font-size: 14px;
                                font-weight: 700;
                            }

                            img {
                                cursor: pointer;
                                margin-left: 8px;
                                display: inline;
                            }
                        }
                    }
                }


                .topics-container {
                    padding: 10px 0;
                    border-top: 1px solid #d4d4d4;
                    border-bottom: 1px solid #d4d4d4;

                    .table-row:nth-child(even) {
                        background: #F2F2F2;
                    }

                    .table-row:nth-child(odd) {
                        background: none;
                    }

                    .table-body {
                        min-height: auto;
                        font-size: 12px;
                    }
                }
            }

            .right-section {
                padding: 10px;
                width: 35%;

                .docviz-preview {
                    position: relative;


                    .file-name-container {
                        position: absolute;
                        bottom: 23%;
                        width: 100%;


                        .download-icon-docviz {
                            cursor: pointer;
                            position: absolute;
                            right: 7%;
                        }

                        p.file-name {
                            width: 75%;
                            left: 6%;
                            position: absolute;
                            font-size: 12px;
                            margin-top: 8px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 41px;
                            letter-spacing: normal;
                            color: #333333;
                        }

                        .file-name.font-size-8 {
                            font-size: 12px;
                        }
                    }

                }

            }
        }

    }
}