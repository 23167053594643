@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

div.date-filter-container {
    position: relative;

    div.filter-container {
        position: absolute;
        width: 440px;
        left: -358px;
        top: 24px;
        background: #F1EEEA;
        box-shadow: -3px -1px 7px rgba(0, 0, 0, 0.25);
        max-height: 500px;
        z-index: 1;

        .header {
            position: absolute;
            top: 7px;
            right: 7px;

            img.cross-image {
                height: 8px;
                cursor: pointer;
            }
        }

        .header.top-30 {
            top: 30px;
        }

        .notification-container {
            display: flex;
            background: #333333;
            justify-content: center;
            height: 24px;


            .notification {
                display: flex;
                align-items: center;

                span.label-text {
                    color: white;
                    margin-left: 5px;
                }
            }
        }

        .month-range-picker-wrapper {
            display: flex;
        }

        .month-range-picker-container {
            padding: 10px 10px 0 10px;

            .month-label-container {
                display: flex;
                justify-content: center;

                .month-label {
                    background: white;
                    display: flex;
                    height: 22px;
                    width: 100px;
                    align-items: center;
                    border-radius: 12px;
                    justify-content: center;
                    font-size: 12px;

                    img {
                        height: 14px;
                    }

                    span.label-text {
                        margin-left: 5px;
                    }
                }

            }

        }

        .filter-separator {
            border: 1px solid $gray--400;
            margin: 6px 10px 0 10px;
        }

        div.filter-action-item {
            height: 44px;
            padding: 8px 12px;
            display: flex;
            justify-content: center;

            .btn {
                text-transform: uppercase;
                margin: 0;
                text-decoration: none;
                font-size: 12px;
                font-weight: 400;
                align-items: center;
                justify-content: center;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
                display: inline-flex;
                font-style: normal;
                border-radius: 0px;
            }

            .btn:focus {
                box-shadow: none;
            }

            .primary.button {
                width: 110px;
                height: 30px;
                background: $bcggreenfive;
                color: $white;
                border: 0px;
                margin-left: 10px;
            }

            .primary.button:hover {
                background-color: $bcggreenfivehover;
            }

            .secondary.button {
                color: $bcggreenfive;
                border: 1px solid;
                height: 30px;
                width: 100px;
            }

            .secondary.button:hover {
                color: $bcggreenfivehover;
            }
        }
    }
}