@import "src/global-styles/colors";
@import "src/global-styles/typography";

.autosuggest {
  &__customize {
    position: relative;
    width: 100%;

    .react-autosuggest {
      &__container {
        width: 100%;
      }

      &__suggestions-container {
        max-width: 770px;
        position: absolute;
        background: #ffffff;
        border-radius: 5px;
        width: 100%;
        margin-top: 4px;
        max-height: 400px;
        z-index: 11;
        // display: none;
      }

      &__noresult {
        border: 1px solid $gray--whitish;
        min-height: 300px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        p {
          font-size: 16px;
          line-height: 22px;
          font-family: $sans-bold;
          margin-top: 28px;
        }

        .mail-button {
          margin-top: 29px;

          &__text {
            display: none;
          }
        }
      }

      &__input {
        padding: 13px 16px 13px 52px;
        max-width: 770px;
        width: 100%;
        font-size: 18px;
        border-radius: 7px;
        border: 1px solid $gray--dark;
        -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
        line-height: 20px;
        font-family: $sans-reg;
        color: $gray--darkest;

        &:focus {
          outline: none;
        }
      }

      &__suggestions-container--open {
        max-height: auto;
        overflow-y: visible;

        .react-autosuggest__suggestion-list-bottom-infoBox {
          display: block;
        }
      }

      &__suggestions-list {
        border: none;
        max-width: 770px;
        -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
        max-height: calc(100vh - 140px);
        margin: 0;
        padding: 8px 0;
        max-height: 400px;
        overflow-y: auto;
      }

      &__suggestion-wrap {
        padding: 10px 15px;
        border: none;
        line-height: 30px;
        font-family: $sans-reg;

        span+span {
          font-size: 14px;
        }

        &-outer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &--highlighter {
          padding: 10px 2px 10px 15px;
        }
      }

      &__suggestion--highlighted>div {
        background-color: $gray--light;
        color: $gray--darkest;
      }

      &__search--icon {
        position: absolute;
        top: 10px;
        left: 15px;
        transform: rotate(90deg);
      }

      &__suggestion-list-bottom-infoBox {
        display: none;
        border-radius: 0 0 10px 10px;
        padding: 8px;
        font-size: 14px;
        color: $white;
        background: $gray--darkest;

        .infoText {
          padding-left: 10px;
        }
      }

      &__tooltip {
        margin-left: 0;

        .tooltipwrap__popup {
          max-width: 700px;
          padding: 10px;
          white-space: pre-wrap;
        }

        &__poly-img {
          .tooltipwrap__popup {
            max-width: 700px;
          }
        }
      }

      &__arrow-right {
        margin: 0 10px 0 5px;
        position: relative;

        &::before {
          content: "";
          width: 10px;
          height: 5px;
          position: absolute;
          top: 2px;
          left: 0px;
          border-radius: 2px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 6px solid $gray--dark;
        }

        &:first-child {
          margin-left: 0;
          display: none;
        }
      }
    }
  }
}