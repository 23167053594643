@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

div.month-range-picker {

    .month-range-picker-header {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 5px;

        .icons {
            box-shadow: 0px 0.75px 0.75px 0px rgba(0, 14, 51, 1);
            width: 33px;
            height: 33px;
            border-radius: 60px;
            cursor: pointer;
            display: flex;
            padding-top: 10px;
            padding-left: 11px;
        }

        .icons.disabled {
            opacity: 0.5;
            cursor: initial;
        }

        .icons.next-icon {
            padding-left: 13px;
        }
    }

    .year-heading {
        font-size: 16px;
        font-weight: 700;
        margin-top: 6px;
    }

    .year-container {
        display: flex;
        flex-wrap: wrap;

        .month-range-picker-cell {
            width: 24%;
            padding: 10px;
            display: flex;
            justify-content: center;
            box-shadow: 0px 0.75px 0.75px 0px #000E330D;
            margin: 1px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 11px;
            font-weight: 700;
        }

        .month-range-picker-cell:hover {
            background: $bcg-green-highlight;
        }

        .month-range-picker-cell.start,
        .month-range-picker-cell.end {
            background: $bcg-green;
            color: #fff;
        }

        .month-range-picker-cell.selected {
            background: $bcg-green-highlight;
            outline: none;
        }

        .month-range-picker-cell.disabled {
            opacity: 0.5;
        }
    }
}